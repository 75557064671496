/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.components{
    background-color: #fff;
}
.components .tim-container .btn{
    margin: 2px;
}
.components .wrapper{
    min-height: 100%;
    height: 100%;
}
.components .navbar-fixed-top{
    left: 0px;
}
.components .navbar.navbar-components{
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    max-height: 70px;
}
.components .navbar.navbar-components .navbar-brand{
    width: auto!important;
    height: auto!important;
    padding-top: 0px;
    margin: 0px;
}
.navbar.navbar-components .navbar-nav p {
    display: inline-block;
    padding-left: 5px;
    margin-bottom: 0;
}
@media (max-width: 992px) {
    .off-canvas-sidebar .components .navbar-collapse{
        min-height: calc(100vh + 36px) !important;
        top: -1px;
        opacity: 0.8;
    }
}
.components .tim-container .navbar.navbar-components .navbar-brand{
    margin: 7px 0px !important;
}
.components .navbar-default.navbar-transparent.navbar-components .navbar-nav>li>a{
    color: #FFF;
}
.components .navbar.navbar-components .navbar-nav > li > a {
    margin: 0px;
    padding: 5px 15px;
}
.components .navbar.navbar-components .navbar-nav > li > a.btn {
    margin: 15px 3px;
    padding: 8px 16px;
}
.components .navbar-components .navbar-title{
    height: 100%;
}
.components .navbar-components .navbar-title h4{
    line-height: 12px;
    margin-top: 16px;
    margin-bottom: 5px;
}

.components .navbar-components .navbar-title img{
    width: 100%
}
.components .navbar-transparent.navbar-components .navbar-toggle .icon-bar,
.components .nav-open .navbar-transparent.navbar-components .navbar-toggle .icon-bar {
    background-color: #fff;
}
/*.header-wrapper .navbar-default{
    box-shadow: 0 14px 20px -12px rgba(0, 0, 0, 0.2);
    color: #444;
    background-color: white;
}*/
.components .header-wrapper .navbar-default.navbar-components .btn{
    color: #444;
}
.components .navbar-components .nav.navbar-nav.navbar-center{
    float: none;
    display: inline-block;
}
.components .navbar-components .navbar-title .image-container{
    height: 40px;
    width: 40px;
    display: inline-block;
    top: -3px;
    position: relative;
}
.components .tim-row{
    margin-bottom: 40px;
}
.components pre.prettyprint{
    background-color: #eee;
    border: 0px;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 20px;
    text-align: left;
}
.components .atv, .components .str{
    color: #05AE0E;
}
.components .tag, .components .pln, .components .kwd{
    color: #3472F7;
}
.components .atn{
    color: #2C93FF;
}
.components .pln{
    color: #333;
}
.components .com{
    color: #999;
}
.components .space-top{
    margin-top: 50px;
}
.components .btn-primary .caret{
    border-top-color: #3472F7;
    color: #3472F7;
}
.components .area-line{
    border: 1px solid #999;
    border-left: 0;
    border-right: 0;
    color: #666;
    display: block;
    margin-top: 20px;
    padding: 8px 0;
    text-align: center;
}
.components .area-line a{
    color: #666;
}
.components .container-fluid{
    padding-right: 15px;
    padding-left: 15px;
}
.components .logo-container .logo{
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
}
.components .header-wrapper {
  position: relative;
  height: 500px;
}

/*.header-wrapper .navbar {
    border-radius: 0;
    width: 100%;
    z-index: 3;
}*/
.components .header-wrapper .header {
    background-position: center center;
    background-size: cover;
    height: 450px;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.components .header-wrapper .header .filter::after {
    background: -moz-linear-gradient(top, #1DC7EA 0%, rgba(5, 143, 170, 0.6) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1DC7EA), color-stop(100%, rgba(5, 143, 170, 0.6)));
    background: -webkit-linear-gradient(top, #1DC7EA 0%, rgba(5, 143, 170, 0.6) 100%);
    background: -o-linear-gradient(top, #1DC7EA 0%, rgba(5, 143, 170, 0.6) 100%);
    background: -ms-linear-gradient(top, #1DC7EA 0%, rgba(5, 143, 170, 0.6) 100%);
    background: linear-gradient(to bottom, #1DC7EA 0%, rgba(5, 143, 170, 0.6) 100%);
    content: "";
    display: block;
    height: 450px;
    left: 0;
    opacity: 0.77;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
.components .header-wrapper .title-container{
    color: #fff;
    position: relative;
    margin-top: 110px;
    z-index: 3;

}
.components .title-container img{
    width: 100px;
    height: auto;
}
.components .logo-container .brand{
    font-size: 18px;
    color: #FFFFFF;
    line-height: 20px;
    float: left;
    margin-left: 10px;
    margin-top: 5px;
    width: 50px;
    height: 50px;
}
.components .logo-container{
    margin-top: 10px;
}
.components .logo-container .logo img{
    width: 100%;
}
.components .navbar-small .logo-container .brand{
    color: #333333;
}
.components .navbar-default .logo-container .brand{
    color: #444;
}
.components .navbar-default.navbar-transparent .logo-container .brand{
    color: #FFFFFF;
}
.components .navbar-defa.components ult.navbar-transparent .navbar-nav>li>a{
    color: #FFFFFF;
}
.components .logo-container{
    margin-top: 10px;
}
.components .logo-container .logo img{
    width: 100%;
}

.components .navbar.navbar-transparent{
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    border: 0;
    padding-top: 0px;
}
.components .fixed-section ul{
    padding: 0;
    margin-bottom: 40px;
}
.components .fixed-section{
    max-height: 70vh;
    border-bottom: 1px solid rgba(128, 128, 128, 0.28);
    border-top: 1px solid rgba(128, 128, 128, 0.28);
    overflow: scroll;
    top: 110px;
}
.components .fixed-section ul li{
    list-style: none;
}
.components .fixed-section li a{
    font-size: 14px;
    padding: 2px;
    display: block;
    color: #666666;
}
.components .fixed-section li a.active{
    color: #00bbff;
}
.components .fixed-section.float{
    position: fixed;
    top: 100px;
    width: 200px;
    margin-top: 0;
}
.components .copyright {
  color: #777777;
  padding: 10px 15px;
  font-size: 14px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}

.components .table-bigboy .img-container{
    width: 130px;
    height: 85px;
}

.components .table-bigboy .td-name{
    min-width: 170px;
}
/*
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
    width: 99.9%; //fix for documentation on single select
}*/

@media (max-width: 991px) {
    .components .fixed-section.affix {
        position: relative;
        margin-bottom: 100px;
    }

    .components .tim-row .nav.nav-pills{
        margin-bottom: 25px;
    }

    .components .nav-mobile-menu .navbar-title{
        display: none;
    }

}

@media (max-width: 1199px) {

  .components .navbar .navbar-brand {
    height: 50px;
    padding: 10px 15px;
  }

}

@media (max-width: 768px) {
  .components .footer .copyright {
    display: inline-block;
    text-align: center;
    padding: 10px 0;
    float: none !important;
    width: 100%;
  }
}

@media (max-width: 830px){
    .components .main-raised{
        margin-left: 10px;
        margin-right: 10px;
    }
}
.components #map {
    position: relative;
    width: 100%;
    height: 300px;
}
.CodeMirror{
  height: auto;
  background-color: #f2f2f2;
  border-radius: 4px;
}
